<template lang="pug">
.-viewer
  .ui.red.label(v-show='nRequests') {{ nRequests }} pending requests...
  .-side-bar
    .-buttons
      .-button(
        v-for='(panel, idx) in panels' :class='{"dark":panels[idx]==currentPanel}'
        @click='setPanel(currentPanel==panels[idx]?null:idx)'
        )
        button.circular.ui.icon.button
          i(:class='buttonIconMap[panels[idx]]').icon
        span {{ buttonTittleMap[panels[idx]] }}
  side-panel.-viewer-side-panel
  big-table.-viewer-table
</template>

<script>
import 'semantic-ui-offline/semantic.css'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
  activated() {
    //! why this?
    this.setSelectedCohortIdx(0)
  },

  components: {
    'side-panel': require('@/components/SidePanel.vue').default,
    'big-table': require('@/components/BigTable.vue').default
  },

  computed: {
    ...mapGetters(['currentPanel', 'loggedIn', 'nRequests']),
    ...mapState(['panels'])
  },

  data() {
    return {
      buttonIconMap: {
        cohort: 'list',
        column: 'th',
        filter: 'filter',
        subCohort: 'copy'
      },
      buttonTittleMap: {
        cohort: 'Cohort List',
        column: 'Columns',
        filter: 'Filters',
        subCohort: 'Save Cohort'
      }
    }
  },

  async created() {
    //!
    this.setMainCreated(true)
    if (!this.loggedIn) await this.me()

    // bigTable mount @scroll after cohort geted
    this.$nextTick(() => {
      this.$root.$emit('Filter:apply', this.name)
    })
  },

  methods: {
    ...mapActions(['me']),
    ...mapMutations([
      'setCurrentpanel',
      'setMainCreated',
      'setSelectedCohortIdx'
    ]),
    setPanel(idx) {
      this.setCurrentpanel(idx)
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/variables.sass"

$header-bottom-border: 2px solid rgba(34, 36, 38, 0.15)
$tittle-color: $LIGHT-COLOR-4
$header-color: $DARK-COLOR-2

.-viewer
  width: 100vw
  top: 0px
  .ui.red.label
    position: absolute
    left: 50%
    transform: translateX(-50%)
  display: flex
  height: 100vh

.-side-bar
  transition: clip-path .1s
  clip-path: inset(0 13em 0 0)
  position: fixed
  padding: 1em 0
  background-color: $LIGHT-COLOR-5
  .-buttons
    display: flex
    flex-direction: column
    margin: 0rem .7rem
    .-button
      border-top-right-radius: 3em
      border-bottom-right-radius: 3em
      background-color: $LIGHT-COLOR-5
      button
        background-color: $LIGHT-COLOR-5
        i
          color: $DARK-COLOR-3
    .-button.dark
      button
        background-color: $LIGHT-COLOR-1
        i
          color: $MAJOR-COLOR-1
  overflow: hidden
  height: calc(100vh - #{$HEADER-HEIGHT})
  z-index: 100
  span
    margin: 1em 8em 1em 1em
    opacity: 0

.-side-bar:hover
  clip-path: inset(0 -10% 0 0)
  border-right: $BORDER
  box-shadow: rgba(184, 194, 215, 0.5) 3px 0px 6px 0px
  .-button:hover
    background-color: $LIGHT-COLOR-3
    button
      background-color: $LIGHT-COLOR-3
  .-button.dark
    background-color: $LIGHT-COLOR-1
    button
      background-color: $LIGHT-COLOR-1
  span
    opacity: 1

.-viewer-side-panel
  // position: fixed
  // right: 0
  height: calc(100vh - #{$HEADER-HEIGHT})
  margin-left: 60px
.-viewer-table
  width: 100vh
@keyframes blinker
  50%
    color: rgba(255, 255, 255, .5)
</style>
// }}}
